import React, { useCallback, useEffect, useRef, useState } from 'react';
import logo from './logo.png';
import {
  Activity,
  BarChart2,
  Bell,
  Zap,
  Check,
  ChevronLeft,
  ChevronRight,
  Menu,
} from 'lucide-react';
import { Button } from './components/ui/button';
import { Card, CardContent } from './components/ui/card';

const logos = [
  {
    name: 'Apollo',
    src: 'https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/vmuehy3j854rybrsrrwe',
  },
  {
    name: 'Clay',
    src: 'https://images.crunchbase.com/image/upload/c_pad,h_256,w_256,f_auto,q_auto:eco,dpr_1/299541eb583641fca14884763666ea39',
  },
  {
    name: 'Outreach',
    src: 'https://images.crunchbase.com/image/upload/c_pad,h_256,w_256,f_auto,q_auto:eco,dpr_1/wb5u1sl5vxvzsf90csiy',
  },
  {
    name: 'Attio',
    src: 'https://images.crunchbase.com/image/upload/c_pad,h_256,w_256,f_auto,q_auto:eco,dpr_1/twrcdhhzbee3uotirwpl',
  },
];

const primaryBgColor = 'bg-gray-900';
const primaryHoverBgColor = 'hover:bg-gray-800';

interface FeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
  <Card className="flex flex-col items-center text-center p-6 bg-white border border-gray-200">
    {icon}
    <h4 className="text-xl font-semibold mt-4 mb-2 text-gray-900">{title}</h4>
    <p className="text-gray-600">{description}</p>
  </Card>
);

interface TestimonialProps {
  content: string;
  author: string;
  role: string;
  company: string;
}

const testimonials = [
  {
    content:
      'Leadoe has completely transformed my outreach strategy. The AI-powered insights are game-changing!',
    author: 'Sarah Johnson',
    role: 'SDR Team Lead',
    company: 'TechCorp',
  },
  {
    content:
      'The activity tracking feature saves me hours each week. I can focus on high-value prospects at just the right time.',
    author: 'Michael Chen',
    role: 'Account Executive',
    company: 'SalesPro Inc.',
  },
  {
    content:
      "Smart Alerts have significantly improved our team's response time. We're seeing a 30% increase in positive engagements.",
    author: 'Emily Rodriguez',
    role: 'Sales Manager',
    company: 'GrowthGenius',
  },
  {
    content:
      "The data-driven approach Leadoe provides has revolutionized how we prioritize our leads. It's an indispensable tool for our sales team.",
    author: 'David Kim',
    role: 'VP of Sales',
    company: 'InnovateX',
  },
  {
    content:
      "Leadoe's integration capabilities have streamlined our entire workflow. It's become the central hub for our outreach efforts.",
    author: 'Laura Martinez',
    role: 'Marketing Director',
    company: 'TechPioneer Ltd.',
  },
];

// const QuoteIcon = ({ className }: { className?: string }) => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 24 24"
//     fill="currentColor"
//     className={className}
//   >
//     <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
//   </svg>
// );

const Testimonial: React.FC<TestimonialProps> = ({
  content,
  author,
  role,
  company,
}) => (
  <Card className="relative bg-white border border-gray-200 overflow-hidden h-full">
    {/*<QuoteIcon className="absolute top-6 left-6 w-12 h-12 text-gray-100" />*/}
    <CardContent className="p-8 relative z-10 h-full flex flex-col">
      <p className="text-gray-600 mb-4 italic relative flex-grow">{content}</p>
      <div className="flex items-center mt-6">
        <div className="w-12 h-12 bg-gray-200 rounded-full mr-4"></div>
        <div>
          <p className="font-semibold text-gray-900">{author}</p>
          <p className="text-sm text-gray-500">
            {role}, {company}
          </p>
        </div>
      </div>
    </CardContent>
  </Card>
);

interface LogoProps {
  src: string;
  name: string;
}

const Logo: React.FC<LogoProps> = ({ src, name }) => (
  <div className="w-24 h-24 rounded-full overflow-hidden">
    <img
      src={src}
      alt={`${name} logo`}
      className="w-full h-full object-cover"
    />
  </div>
);

const LandingPage = () => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollCarousel = (direction: 'left' | 'right') => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToSection = useCallback((sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="min-h-screen bg-white text-gray-900">
      {/* Header */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img
                src={logo} // Use the imported logo here
                alt="Leadoe Logo"
                className="w-8 h-8 mr-2 sm:mr-3 object-cover"
              />
              <h1 className="text-4xl sm:text-3xl font-bold text-gray-800">
                Leadoe
              </h1>
            </div>
            <nav className="hidden md:flex items-center">
              <Button
                variant="ghost"
                className="mr-4 text-gray-600 hover:text-gray-900"
                onClick={() => scrollToSection('features')}
              >
                Features
              </Button>
              <Button
                variant="ghost"
                className="mr-4 text-gray-600 hover:text-gray-900"
                onClick={() => scrollToSection('pricing')}
              >
                Pricing
              </Button>
              <Button
                variant="outline"
                className="mr-4 border-gray-300 text-gray-600 hover:text-gray-900"
              >
                Log In
              </Button>
              <Button
                className={`${primaryBgColor} text-white ${primaryHoverBgColor}`}
              >
                Sign up
              </Button>
            </nav>
            <Button variant="ghost" className="md:hidden">
              <Menu className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          Supercharge Your LinkedIn Engagement
        </h2>
        <p className="mt-10 max-w-xl mx-auto text-xl text-gray-500">
          Track, analyze, and optimize your LinkedIn outreach with AI-powered
          insights.
        </p>
        <div className="mt-10 flex justify-center space-x-4">
          <Button
            size="lg"
            className={`px-8 py-3 text-lg ${primaryBgColor} text-white ${primaryHoverBgColor}`}
          >
            Start for Free
          </Button>
          <Button
            size="lg"
            variant="outline"
            className="px-8 py-3 text-lg border-gray-300 text-gray-600 hover:text-gray-900"
          >
            See how it works
          </Button>
        </div>
      </section>

      {/* Logo Carousel */}
      <section className="max-w-4xl mx-auto px-3 sm:px-6 lg:px-8 pb-8">
        <div className="flex justify-between items-center">
          {logos.map((logo, i) => (
            <div key={i} className="flex justify-center">
              <Logo src={logo.src} name={logo.name} />
            </div>
          ))}
        </div>
      </section>

      {/* Features Section */}
      <section
        id="features"
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h3 className="text-3xl font-bold text-center mb-12 text-gray-900">
          Key Features
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Feature
            icon={<Activity size={48} className="text-gray-800" />}
            title="Activity Tracking"
            description="Monitor profile changes, new experiences, posts, and comments of your targeted LinkedIn users."
          />
          <Feature
            icon={<BarChart2 size={48} className="text-gray-800" />}
            title="Advanced Analytics"
            description="Get detailed insights and visualizations of LinkedIn engagement patterns and trends."
          />
          <Feature
            icon={<Zap size={48} className="text-gray-800" />}
            title="AI-Powered Insights"
            description="Leverage machine learning to optimize your engagement strategies and improve outreach effectiveness."
          />
          <Feature
            icon={<Bell size={48} className="text-gray-800" />}
            title="Smart Alerts"
            description="Receive timely notifications for high-value engagement opportunities with your prospects."
          />
        </div>
      </section>

      {/* Pricing Section */}
      <section
        id="pricing"
        className="w-full py-12 md:py-24 lg:py-32 bg-gray-50"
      >
        <div className="container px-4 md:px-6">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12 text-gray-900">
            Simple, transparent pricing
          </h2>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 lg:max-w-6xl lg:mx-auto">
            <Card className="bg-white shadow-sm border border-gray-200">
              <CardContent className="space-y-4">
                <h3 className="text-2xl font-bold text-gray-900">
                  Forever Free
                </h3>
                <Button
                  className={`w-full ${primaryBgColor} text-white ${primaryHoverBgColor}`}
                >
                  Get Started
                </Button>
                <p className="text-4xl font-bold text-gray-900">$0</p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Track up to 10 profiles
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Daily activity monitoring
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Weekly AI-powered insights*
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">Community support</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
            <Card className="bg-white shadow-md border-2 border-blue-500 relative">
              <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 text-sm font-semibold rounded-bl">
                Most Popular
              </div>
              <CardContent className="space-y-4">
                <h3 className="text-2xl font-bold text-gray-900">Pro</h3>
                <Button
                  className={`w-full ${primaryBgColor} text-white ${primaryHoverBgColor}`}
                >
                  Upgrade to Pro
                </Button>
                <div>
                  <p className="text-4xl font-bold text-gray-900">
                    $8
                    <span className="text-sm font-normal text-gray-500">
                      {' '}
                      / mo
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    $10/mo if billed monthly
                  </p>
                </div>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Track up to 50 profiles
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Frequent activity monitoring
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Daily AI-powered insights*
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">Email support</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">Integrations</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
            <Card className="bg-white shadow-sm border border-gray-200">
              <CardContent className="space-y-4">
                <h3 className="text-2xl font-bold text-gray-900">Enterprise</h3>
                <Button
                  className={`w-full ${primaryBgColor} text-white ${primaryHoverBgColor}`}
                >
                  Contact Us
                </Button>
                <p className="text-4xl font-bold text-gray-900">Custom</p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Unlimited profile tracking
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Login with Single Sign-On (SSO)
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Billing and Seat Manager roles
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">
                      Advanced AI-powered insights*
                    </span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">Dedicated support</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-gray-900" />
                    <span className="text-gray-600">Custom integrations</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
          <p className="text-center text-sm text-gray-500 mt-8">
            * AI-powered insights are generated based on the collected data and
            may vary in frequency and depth depending on the activity level of
            tracked profiles.
          </p>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h3 className="text-3xl font-bold text-center mb-12 text-gray-900">
          What our users say
        </h3>
        <div className="relative">
          <div
            ref={carouselRef}
            className="flex overflow-x-auto scrollbar-hide snap-x snap-mandatory"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex-none w-full md:w-1/3 px-4 snap-start"
              >
                <Testimonial {...testimonial} />
              </div>
            ))}
          </div>
          {showLeftArrow && (
            <button
              onClick={() => scrollCarousel('left')}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-r-full p-3 shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 z-10"
              aria-label="Previous testimonials"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => scrollCarousel('right')}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-l-full p-3 shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 z-10"
              aria-label="Next testimonials"
            >
              <ChevronRight className="w-6 h-6 text-gray-600" />
            </button>
          )}
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
          <h3 className="text-3xl font-bold mb-4 text-gray-900">
            Ready to elevate your LinkedIn strategy?
          </h3>
          <p className="text-xl mb-8 text-gray-600">
            Join thousands of SDRs who are already optimizing their outreach
            with Leadoe.
          </p>
          <Button
            size="lg"
            className={`px-8 py-3 text-lg ${primaryBgColor} text-white ${primaryHoverBgColor}`}
          >
            Start for Free
          </Button>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-100 text-gray-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h4 className="text-lg font-semibold mb-4 text-gray-900">
                Product
              </h4>
              <ul className="space-y-2">
                <li>Features</li>
                <li>Pricing</li>
                <li>Case Studies</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4 text-gray-900">
                Company
              </h4>
              <ul className="space-y-2">
                <li>About Us</li>
                <li>Careers</li>
                <li>Blog</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4 text-gray-900">
                Resources
              </h4>
              <ul className="space-y-2">
                <li>Documentation</li>
                <li>API</li>
                <li>Support</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4 text-gray-900">
                Legal
              </h4>
              <ul className="space-y-2">
                <li>Privacy Policy</li>
                <li>Terms of Service</li>
                <li>Cookie Policy</li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-200 text-center">
            <p>&copy; 2024 Leadoe. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
