import React from 'react';

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({ className = '', children }) => (
  <div
    className={`rounded-lg border bg-white text-gray-900 shadow-sm ${className}`}
  >
    {children}
  </div>
);

interface CardContentProps {
  className?: string;
  children: React.ReactNode;
}

export const CardContent: React.FC<CardContentProps> = ({
  className = '',
  children,
}) => <div className={`p-6 ${className}`}>{children}</div>;
